#gde-top
  position: relative
  background-image: url(../images/gde-wave.png)
  background-repeat: no-repeat
  background-position: center center
  -webkit-background-size: cover
  background-size: cover
  padding-bottom: 150px
  padding-top: 130px
  margin-bottom: -120px
  @media only screen and (max-width: 567px)
    margin-bottom: 0
    padding-bottom: 50px
    padding-top: 20px
    background-image: url(../images/gde-wave@mobile.png)
  & .container
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    padding-top: 50px
    padding-bottom: 50px
    @media only screen and (max-width: 992px)
      padding-top: 0
      padding-bottom: 0
    @media only screen and (max-width: 567px)
      flex-direction: column
  & .gde-top
    &__title
      width: 56%
      @media only screen and (max-width: 567px)
        width: 245px
        margin-right: auto
      & h1
        font-family: 'Gilroy'
        font-style: normal
        font-weight: 300
        font-size: 57px
        line-height: calc(68/57)
        text-align: right
        letter-spacing: 0.02em
        color: #0C3B60
        margin: 0
        @media only screen and (max-width: 992px)
          font-size: 35px
        @media only screen and (max-width: 567px)
          font-size: 22px
        & b
          font-size: 63px
          font-weight: 700
          @media only screen and (max-width: 992px)
            font-size: 40px
          @media only screen and (max-width: 567px)
            font-size: 24px

    &__image
      width: 35%
      @media only screen and (max-width: 567px)
        width: 65%
        margin-top: 25px
        margin-left: auto
      & img
        width: 100%
        height: auto
        -webkit-box-sizing: border-box
        -moz-box-sizing: border-box
        box-sizing: border-box

