//@import "./partials/normalize"
//@import "./partials/variables"
@import "./partials/colors"
//@import "partials/fonts"




html
  padding: 0
  margin: 0
body
  padding: 0
  margin: 0
p
  padding: 0
  margin: 0


main
  position: relative

.btn-arrow
  text-decoration: none
  color: #1A1C26
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: flex-end
  & .btn-arrow-title
    font-family: 'Gilroy'
    font-style: normal
    font-weight: 500
    font-size: 39.8362px
    line-height: 48px
    letter-spacing: 0.02em
    color: #1A1C26
    @media only screen and (max-width: 992px)
      font-size: 26px
    @media only screen and (max-width: 567px)
  & .btn-arrow-icon
    margin-left: 20px
    margin-bottom: 10px
    transition: ease-out .3s
    @media only screen and (max-width: 992px)
      margin-left: 10px
      margin-bottom: 12px
    & svg
      fill: #1A1C26
      width: 19px
      height: auto
      box-sizing: border-box
      @media only screen and (max-width: 992px)
        width: 16px
  &:hover
    cursor: pointer
    & .btn-arrow-icon
      margin-left: 30px
      @media only screen and (max-width: 992px)
        margin-left: 15px






@import "./partials/header"
@import "./partials/top"
@import "./partials/about"
@import "./partials/multi"
@import "./partials/footnote"
@import "./partials/gde-shops"
@import "./partials/gde-top"





.btn-bag
  position: fixed
  right: 14%
  bottom: 25%
  background: #5394D6
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1)
  backdrop-filter: blur(31px)
  border-radius: 100px
  width: 110px
  height: 110px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  transition: ease-out .3s
  text-decoration: none
  z-index: 9999
  @media only screen and (max-width: 992px)
    width: 80px
    height: 80px
    right: 2.5%
  @media only screen and (max-width: 567px)
    width: 56px
    height: 56px
    bottom: 50%


  &--icon
    & svg
      width: 32px
      height: auto
      box-sizing: border-box
      fill: #E8E36E
      transition: ease-out .3s
      @media only screen and (max-width: 992px)
        width: 24px
      @media only screen and (max-width: 567px)
        width: 15px
  &--text
    font-family: 'Gilroy'
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: calc(17/14)
    color: #FFFFFF
    @media only screen and (max-width: 992px)
      font-size: 11px
    @media only screen and (max-width: 567px)
      font-size: 8px
  &:hover
    cursor: pointer
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25)
    background-color: #96C4DB
    & .btn-bag--icon
      & svg
        fill: #ffffff



@import "./partials/media"
