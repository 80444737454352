#header
  padding-top: 40px
  position: relative
  top: 0
  z-index: 9999
  transition: ease-out .3s
  @media only screen and (max-width: 992px)
    padding-top: 20px
    //padding-bottom: 20px
  @media only screen and (max-width: 567px)
    padding-top: 10px
  & .container
    z-index: 8888
    position: relative
  & .header-line
    border: 1px solid #E9E9E9
    margin-top: 60px
    width: 100%
    position: relative
    z-index: 7778
    @media only screen and (max-width: 992px)
      display: none
  & .header-bg
    background-color: #ffffff
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    width: 100%
    height: 100%
    z-index: 7777
  & .container
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center

  & .header
    &__logo
      & a
        text-decoration: none
        color: #1A1C26
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: center
        width: 233px
        @media only screen and (max-width: 992px)
          width: 150px
        @media only screen and (max-width: 567px)
          width: 103px
        & .logo-img
          & img
            width: 100%
            height: auto
            box-sizing: border-box
            display: block


        & .logo-text
          font-family: 'Gilroy'
          font-style: normal
          font-weight: 300
          font-size: 11.78px
          line-height: 1
          margin-top: 5px
          color: #1A1C26
          display: block
          @media only screen and (max-width: 992px)
            font-size: 7.55px
            line-height: 1
            margin-top: 2px
          @media only screen and (max-width: 567px)
            font-size: 5.2px
    &__navs
      margin-left: 70px
      margin-bottom: 13px
      @media only screen and (max-width: 992px)
        margin-left: 0
        //display: none
        visibility: hidden
        height: 0
        position: absolute
        top: -500px
        left: 50%

      & .nav
        &-ul
          display: flex
          flex-direction: row
          justify-content: flex-start
          align-items: center
          list-style: none
          flex-wrap: wrap
          padding: 0
          margin: 0
        &-item
          margin-right: 20px
          margin-top: 4px
          margin-bottom: 4px
          position: relative
          @media only screen and (max-width: 1500px)
            margin-right: 15px
          &:last-child
            margin-right: 0
          & a
            font-family: 'Gilroy'
            font-style: normal
            font-weight: 600
            font-size: 18px
            line-height: calc(22/18)
            color: #1A1C26
            text-decoration: none
            position: relative
            transition: ease-out .3s
            @media only screen and (max-width: 1700px)
              font-size: 17px
            @media only screen and (max-width: 1500px)
              font-size: 16px
            &:before
              content: ""
              position: absolute
              bottom: 0
              opacity: 0
              left: 5%
              width: 90%
              height: 2px
              background-color: #e09400
              transition: ease-out .3s
            &:hover
              cursor: pointer
              &:before
                bottom: -5px
                opacity: 1
          & .search-btn
            position: relative
            background-color: #ffffff
            width: 26px
            height: 26px
            min-width: 26px
            min-height: 26px
            max-width: 26px
            max-height: 26px
            display: inline-flex
            border-radius: 50%
            flex-direction: column
            justify-content: center
            align-items: center
            filter: drop-shadow(0px 4px 4px rgba(118, 118, 118, 0.15))
            & img
              width: 12px
              height: 12px
              box-sizing: border-box
      &.show
        visibility: visible
        height: auto
        position: absolute
        background-color: #FFFFFF
        padding: 16px 32px
        border: 1px solid rgba(249, 249, 249, 0.8)
        box-shadow: -4px -4px 16px rgba(255, 255, 255, 0.25), 4px 4px 8px rgba(66, 83, 107, 0.09), inset -2px -2px 25px rgba(182, 182, 182, 0.25), inset -4px -6px 25px #FFFFFF, inset 2px 2px 6px rgba(66, 83, 107, 0.22)
        border-radius: 20px
        top: 50px
        width: 240px
        margin-left: -120px
        @media only screen and (max-width: 567px)
          top: 30px
        & .nav-ul
          flex-direction: column
          justify-content: flex-start
          & .nav-item
            margin-right: 0
            text-align: center

    &__burger
      display: none
      //margin-left: 27%
      margin-bottom: 16px
      width: 100%
      flex-grow: 1
      text-align: center
      margin-right: 22%
      @media only screen and (max-width: 992px)
        display: block
      @media only screen and (max-width: 567px)
        margin-right: 30%
      & .burger-menu
        position: relative
        & span
          display: block
          width: 20px
          height: 2.5px
          background: #FFFFFF
          border: 0.125px solid #0C3B60
          border-radius: 3px
          position: relative
          transition: ease-out .3s
          &:before
            content: ""
            background: #0C3B60
            border-radius: 3px
            width: 20px
            height: 2.5px
            position: absolute
            top: 50%
            margin-top: -1.25px
            left: 50%
            margin-left: -10px
            transform: translateY(-7px)
            transition: ease-out .3s
          &:after
            content: ""
            background: #0C3B60
            border-radius: 3px
            width: 20px
            height: 2.5px
            position: absolute
            top: 50%
            margin-top: -1.25px
            left: 50%
            margin-left: -10px
            transform: translateY(7px)
            transition: ease-out .3s

        &.show
          & span
            border: 0.125px solid transparent
            &:before
              transform: rotate(45deg)
              transform-origin: center center
            &:after
              transform: rotate(-45deg)
              transform-origin: center center
        &:focus
          outline: none
        &:active
          outline: none



  &.front-page
    & .header__navs
      margin-right: 23%
      @media only screen and (max-width: 1500px)
        margin-right: 30%
    & .header-line
      width: 65.3%
      @media only screen and (max-width: 1500px)
        width: 65%
    & .header-bg
      width: 65%
      @media only screen and (max-width: 992px)
        width: 55%
  &.position
    position: fixed
    top: -200px
    //height: 0
    //visibility: hidden
    & .header-line
      margin-top: 20px
      @media only screen and (max-width: 992px)
        display: block
      @media only screen and (max-width: 567px)
        margin-top: 10px
    & .header__navs
      & .nav-ul
        & .nav-item
          & a
            @media only screen and (max-width: 1500px)
              font-size: 17px

  &.active
    //visibility: visible
    top: 0
    width: 100%
    height: auto
    left: 0
    right: 0
    padding-top: 20px
    @media only screen and (max-width: 567px)
      padding-top: 10px





