#multi
  margin-top: 130px
  background-image: url(../images/multi__bg@desktop.png)
  background-repeat: no-repeat
  background-position: center top
  background-size: cover
  @media only screen and (max-width: 567px)
    margin-top: 30px
    background-image: none
  & .container
  & .multi
    &__block
      display: grid
      grid-template-columns: auto auto
      grid-template-rows: auto auto
      gap: 40px 80px
      grid-template-areas: "multi__item-1 multi__item-2" "multi__item-1 multi__item-3"
      @media only screen and (max-width: 992px)
        grid-template-columns: auto auto
        grid-template-rows: auto auto
        gap: 30px 30px
        grid-template-areas: "multi__item-2 multi__item-3" "multi__item-1 multi__item-1"
      @media only screen and (max-width: 567px)
        grid-template-columns: auto
        grid-template-rows: auto auto auto
        gap: 30px 0px
        grid-template-areas: "multi__item-2" "multi__item-1" "multi__item-3"
    &__item
      background: #FFFFFF
      box-shadow: -4.42624px -6.63936px 17.705px rgba(255, 255, 255, 0.5), 4.42624px 6.63936px 17.705px rgba(163, 177, 198, 0.5)
      overflow: hidden
      @media only screen and (max-width: 567px)
        box-shadow: -2.68px -4.02px 10.72px rgba(255, 255, 255, 0.5), 2.68px 4.02px 10.72px rgba(163, 177, 198, 0.5)
      &-1
        grid-area: multi__item-1
        padding: 55px 33px 90px
        border-radius: 22px
        display: flex
        flex-direction: column
        justify-content: space-between
        @media only screen and (max-width: 567px)
          border-radius: 14px
          padding: 30px 18px 50px
        & .block
          & p
            font-family: 'Gilroy'
            font-style: normal
            font-weight: 300
            font-size: 22px
            line-height: calc(27/22)
            color: #1A1C26
            @media only screen and (max-width: 992px)
              font-size: 18px
            @media only screen and (max-width: 567px)
              font-size: 14px
        & .image
          margin-top: 50px
          @media only screen and (max-width: 567px)
            margin-top: 65px
          & img
            width: 100%
            height: auto
            box-sizing: border-box
            display: block
      &-2
        grid-area: multi__item-2
        border-radius: 20px
        @media only screen and (max-width: 567px)
          border-radius: 14px
        & .block
          padding: 30px 18px
          @media only screen and (max-width: 567px)
            padding: 10px 16px 20px 16px
          & p
            margin-top: 34px
            font-family: 'Gilroy'
            font-style: normal
            font-weight: 300
            font-size: 18px
            line-height: calc(21/18)
            color: #000000
            @media only screen and (max-width: 992px)
              font-size: 14px
              margin-top: 15px
        & .image
          & img
            width: 100%
            height: auto
            box-sizing: border-box
            display: block
      &-3
        grid-area: multi__item-3
        border-radius: 20px
        @media only screen and (max-width: 567px)
          border-radius: 14px
        & .block
          padding: 30px 18px
          @media only screen and (max-width: 567px)
            padding: 10px 16px 20px 16px
          & p
            margin-top: 34px
            font-family: 'Gilroy'
            font-style: normal
            font-weight: 300
            font-size: 18px
            line-height: calc(21/18)
            color: #000000
            @media only screen and (max-width: 992px)
              font-size: 14px
              margin-top: 15px
        & .image
          & img
            width: 100%
            height: auto
            box-sizing: border-box
            display: block


