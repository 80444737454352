#top
  background-image: url(../images/top__bg@desktop.png)
  background-repeat: no-repeat
  background-position: center bottom
  background-size: cover
  margin-top: 100px
  padding-bottom: 280px
  @media only screen and (max-width: 992px)
    background-image: url(../images/top__bg@tablet.png)
    padding-bottom: 150px
    background-size: contain
    margin-top: 40px
  @media only screen and (max-width: 567px)
    margin-top: 5px
    padding-bottom: 75px
  & .container
    display: flex
    flex-direction: row
    justify-content: flex-start
    z-index: 110
    position: relative
    @media only screen and (max-width: 992px)
      flex-direction: column

  & .top
    &__block
      @media only screen and (max-width: 992px)
        width: 47%
      @media only screen and (max-width: 567px)
        width: 48%
      & .block
        &__title
          & h1
            font-family: 'Gilroy'
            font-style: normal
            font-weight: 300
            font-size: 57px
            line-height: calc(68/57)
            text-align: right
            letter-spacing: 0.02em
            color: #0C3B60
            margin: 0
            @media only screen and (max-width: 992px)
              font-size: 40px
            @media only screen and (max-width: 567px)
              font-size: 22px
            & b
              font-size: 63px
              font-weight: 700
              border-bottom: 2px solid #E8E26D
              @media only screen and (max-width: 992px)
                font-size: 45px
              @media only screen and (max-width: 567px)
                font-size: 24px
        &__description
          margin-top: 10px
          @media only screen and (max-width: 567px)
            display: none
          & p
            text-align: right
            font-family: 'Gilroy'
            font-style: normal
            font-weight: 200
            font-size: 15px
            line-height: 1.2
            letter-spacing: 0.0075em
            color: #1A1C26
            @media only screen and (max-width: 992px)
              font-size: 12px
            @media only screen and (max-width: 567px)
              font-size: 14px
            &:last-child
              font-size: 13px
              @media only screen and (max-width: 992px)
                font-size: 10px
              @media only screen and (max-width: 567px)
                font-size: 14px
          &.show
            display: block
            position: absolute
            top: 80px
            width: 90%
            left: 5%
            background-color: #FFFFFF
            border-radius: 20px
            padding: 8px 16px
            border: 1px solid rgba(249, 249, 249, 0.8)
            box-shadow: -4px -4px 16px rgba(255, 255, 255, 0.25), 4px 4px 8px rgba(66, 83, 107, 0.09), inset -2px -2px 25px rgba(182, 182, 182, 0.25), inset -4px -6px 25px #FFFFFF, inset 2px 2px 6px rgba(66, 83, 107, 0.22)
            & p
              text-align: left
        &__plus
          display: none
          text-align: right
          margin-top: 10px
          @media only screen and (max-width: 567px)
            display: block
          & svg
            width: 16px
            height: auto
            box-sizing: border-box
            fill: #5394D6

        &__btn
          margin-top: 50px
          text-align: right
          @media only screen and (max-width: 567px)
            margin-top: 20px
          & a
            background: #5394D6
            mix-blend-mode: normal
            box-shadow: -2px -4px 6px rgba(233, 242, 251, 0.5), 2px 4px 10px rgba(80, 153, 226, 0.5)
            border-radius: 100px
            font-family: 'Gilroy'
            font-style: normal
            font-weight: 600
            font-size: 18px
            line-height: calc(22/18)
            color: #FFFFFF
            text-decoration: none
            padding: 14px 64px
            transition: ease-out .3s
            @media only screen and (max-width: 992px)
              font-size: 14px
              padding: 14px 40px
            @media only screen and (max-width: 567px)
              font-size: 14px
              line-height: calc(17/14)
              padding: 8px 25px
            &:hover
              cursor: pointer
              background-color: #96C4DB



    &__image
      margin-left: 50px
      max-width: 464px
      padding-top: 80px
      @media only screen and (max-width: 992px)
        margin-left: 60px
        max-width: 60%
      @media only screen and (max-width: 567px)
        margin-left: 15px
        padding-top: 25px
        max-width: 62%
      & img
        width: 100%
        height: auto
        box-sizing: border-box
        display: block
  & .human
    position: absolute
    top: 0
    right: 0
    width: 35%
    height: 713px
    z-index: 100
    background-image: url(../images/top__human@desktop.png)
    background-repeat: no-repeat
    background-position: right top
    background-size: contain
    @media only screen and (max-width: 992px)
      background-image: url(../images/top__human@tablet.png)
      width: 45%
      height: 530px
    @media only screen and (max-width: 567px)
      width: 47%


#scrolling
  text-align: center
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  @media only screen and (max-width: 992px)
    position: relative
    margin-top: -100px
  @media only screen and (max-width: 567px)
    display: none
  & .scrolling-icon
    & svg
      width: 30px
      height: auto
      display: inline-block
      -webkit-box-sizing: border-box
      -moz-box-sizing: border-box
      box-sizing: border-box
      @media only screen and (max-width: 992px)
        width: 25px
    &-dot
      animation: blink-1 3s infinite both
    &:hover
      cursor: pointer


@keyframes blink-1
  0%,50%, 100%
    opacity: 1
  25%, 75%
    opacity: 0
