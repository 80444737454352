#about-title
  padding-top: 150px
  @media only screen and (max-width: 992px)
    padding-top: 50px
  @media only screen and (max-width: 567px)
    padding-top: 0
  & .container
  & h2
    margin: 0
    font-family: 'Gilroy'
    font-style: normal
    font-weight: 300
    font-size: 44px
    line-height: calc(53/44)
    letter-spacing: 0.02em
    color: #0C3B60
    @media only screen and (max-width: 992px)
      font-size: 40px
    @media only screen and (max-width: 567px)
      font-size: 22px
    & b
      font-weight: 500
  & .description
    & p
      font-family: 'Gilroy'
      font-style: normal
      font-weight: 300
      font-size: 18px
      line-height: calc(22/18)
      color: #1A1C26
      @media only screen and (max-width: 992px)
        font-size: 16px
      @media only screen and (max-width: 567px)
        font-size: 14px

#about-block
  background-image: url(../images/about-block__bg@desktop.jpg)
  background-repeat: no-repeat
  background-position: center center
  background-size: cover
  margin-top: 39px
  @media only screen and (max-width: 567px)
    background-image: url(../images/about-block__bg@mobile.jpg)
    margin-top: 25px
  & .container
    padding-top: 100px
    padding-bottom: 100px
    @media only screen and (max-width: 567px)
      padding-top: 35px
      padding-bottom: 35px
  & .row
  & .about-item
    background: linear-gradient(115.99deg, rgba(162, 158, 158, 0.5) 0%, rgba(196, 191, 191, 0) 100%)
    border: 2.12569px solid #FFFFFF
    filter: drop-shadow(4.25139px 4.25139px 10.6285px rgba(0, 0, 0, 0.1))
    backdrop-filter: blur(32.9482px)
    border-radius: 10.6285px
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    padding: 31.8854px 37.1996px 31px
    gap: 20px
    box-sizing: border-box
    height: 100%
    @media only screen and (max-width: 567px)
      padding: 28.209px 32.9104px
      gap: 18.81px
    &__image
      & img
        width: 120px
        height: auto
        display: block
        box-sizing: border-box
        background: linear-gradient(115.99deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)
        border: 2.58256px solid #FFFFFF
        filter: drop-shadow(5.16513px 5.16513px 12.9128px rgba(0, 0, 0, 0.1))
        backdrop-filter: blur(40.0297px)
        border-radius: 50%
        @media only screen and (max-width: 992px)
          width: 100px
        @media only screen and (max-width: 567px)
          width: 94px

    &__text
      font-family: 'Gilroy'
      font-style: normal
      font-weight: 400
      font-size: 18px
      line-height: calc(22/18)
      text-align: center
      color: #FFFFFF
      @media only screen and (max-width: 992px)
        font-size: 16px
      @media only screen and (max-width: 567px)
        color: #1A1C26
        font-weight: 600
      & sup
        font-size: 12px
        font-weight: 300
        @media only screen and (max-width: 567px)
          font-size: 10px



