#footnote
  margin-top: 140px
  margin-bottom: 200px
  @media only screen and (max-width: 992px)
    margin-top: 100px
    margin-bottom: 160px
  @media only screen and (max-width: 567px)
    margin-top: 48px
    margin-bottom: 100px
  & .container
  & p
    font-family: 'Gilroy'
    font-style: normal
    font-weight: 300
    font-size: 18px
    line-height: 22px
    text-align: center
    color: #000000
    @media only screen and (max-width: 992px)
      font-size: 16px
    @media only screen and (max-width: 567px)
      text-align: left
      font-size: 14px
      line-height: 17px

