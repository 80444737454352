#gde-shops
  background-image: url(../images/gde-shops__bg@desktop.jpg)
  background-repeat: no-repeat
  background-position: center center
  background-size: cover
  padding-top: 100px
  padding-bottom: 75px
  @media only screen and (max-width: 992px)
    padding-top: 50px
    padding-bottom: 25px
  @media only screen and (max-width: 567px)
    background-image: url(../images/gde-shops__bg@mobile.jpg)

  & .container

  & .shop-item
    background: linear-gradient(115.99deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%)
    border: 2px solid #FFFFFF
    filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.1))
    backdrop-filter: blur(31px)
    border-radius: 10px
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    padding: 50px
    @media only screen and (max-width: 567px)
      max-width: 70%
      margin-left: auto
      margin-right: auto
      padding: 25px
    & .icon
      text-align: center
      & img
        height: 70px
        width: auto
        display: block
        -webkit-box-sizing: border-box
        -moz-box-sizing: border-box
        box-sizing: border-box
        @media only screen and (max-width: 567px)
          height: 60px

    & .link
      margin-top: 20px
      @media only screen and (max-width: 567px)
        margin-top: 15px
      & a
        font-family: 'Gilroy'
        font-style: normal
        font-weight: 600
        font-size: 20px
        line-height: calc(25/20)
        text-align: center
        text-decoration-line: underline
        color: #E09400


